html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9; /* Light background */
  font-family: Arial, sans-serif;
}

/* Container for the chat messages */
.chat-container {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* User message bubble (blue, aligned to the right) */
.user-message {
  align-self: flex-end;
  background-color: #007aff; /* iMessage blue */
  color: white;
  padding: 10px 15px;
  border-radius: 18px;
  border-bottom-right-radius: 4px; /* iPhone-style corner */
  margin: 5px;
  max-width: 60%;
  word-wrap: break-word;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Assistant message bubble (grey, aligned to the left) */
.assistant-message {
  align-self: flex-start;
  background-color: #e5e5ea; /* iMessage grey */
  color: black;
  padding: 10px 15px;
  border-radius: 18px;
  border-bottom-left-radius: 4px; /* iPhone-style corner */
  margin: 5px;
  max-width: 60%;
  word-wrap: break-word;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Input area styling */
.input-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

button.send-button {
  background-color: #007aff;
}

button.clear-button {
  background-color: #ff3b30;
}
